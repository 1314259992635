import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import Page from "../components/page/Page";
import "./success.css";
import { PrimaryButton } from "@metyis-ds/button";

export default () => {
  const title =
    typeof localStorage !== "undefined" &&
    localStorage.getItem("redirectPageTitle");
  const text =
    typeof localStorage !== "undefined" &&
    localStorage.getItem("redirectPageText");

  useEffect(() => {
    if (title === "DCRA - Digital Commerce Readiness Assessment") {
      return; // Skip redirect and present link to google.com
    }

    const redirectTimeout = setTimeout(() => {
      if (typeof window !== "undefined") {
        localStorage.removeItem("redirectPageTitle");
        localStorage.removeItem("redirectPageText");
      }

      navigate("/");
    }, 5000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <Page>
      <div className="redirect-page">
        {title && text && (
          <>
            <div className="redirect-title-wrapper">
              <h2 className="redirect-title">{title}</h2>
            </div>
            <div className="redirect-text-wrapper">
              <span className="redirect-text">{text}</span>
              {title === "Digital Commerce Readiness Assessment" ? (
                <PrimaryButton
                  onClick={() => {
                    window &&
                      window
                        .open(
                          "https://assets.ctfassets.net/zuzzsqg9enty/1sDO1bbkVnFG3uezjoXbaq/60bbab2af39775ad475ba3e91746bba0/Metyis_-_DCRA_white_paper_V2.pdf",
                          "_blank"
                        )
                        .focus();
                  }}
                >
                  Download Whitepaper
                </PrimaryButton>
              ) : (
                <span>Redirecting you to the homepage...</span>
              )}
            </div>
          </>
        )}
      </div>
    </Page>
  );
};
